/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Header from './header';
import './layout.scss';
import AppIcon from '../images/app-icon.png';
import AppStoreLogo from '../images/app-store.svg';

const ANALYTICS_KEY = process.env.GATSBY_SEGMENT_ANALYTICS_KEY;
const ANALYTICS_URL = process.env.GATSBY_SEGMENT_ANALYTICS_URL;

const Layout = ({ children, hideActions, pageName }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        {/* TODO: REMOVE THIS META TAG BEFORE GOING LIVE */}
        <meta name="robots" content="noindex" />
        <link rel="stylesheet" href="https://use.typekit.net/vdl0gmf.css" />
        <script>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${ANALYTICS_URL}";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
            analytics.load("${ANALYTICS_KEY}");
          }}();
        `}</script>
        <script>{`
          analytics.page("${pageName}")
        `}
        </script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} hideActions={hideActions} />
      <main style={{ minHeight: '60vh' }}>
        {children}
      </main>
      <footer className="footer u-hide-from-webviews">
        <div className="footer__plug">
          <div className="sell-layout">
            <div className="footer__contact">
              <h4 className="footer__contact-title">Need Help?</h4>
              <h2 className="footer__contact-cta">Visit the <a href="https://support.ebth.com" target="_blank" rel="noopener noreferrer" className="footer__plug-link">Help Center</a></h2>
            </div>
            <div className="footer__app">
              <div className="footer__app-promo">
                <a href="https://appsto.re/us/2Pvg6.i" className="footer__app-link" target="_blank" rel="noopener noreferrer">
                  <div className="footer__app-icon">
                    <img src={AppIcon} alt="EBTH iOS App logo" />
                  </div>
                  <div className="footer__app-store-logo">
                    <img src={AppStoreLogo} alt="App Store badge" />
                  </div>
                </a>
              </div>
            </div>
            <div className="footer__copyright">
              © {new Date().getFullYear()} High Road Holdings, LLC
              <a href="https://www.ebth.com/terms" className="footer__copyright-link">Terms</a>
              <a href="https://www.ebth.com/privacy" className="footer__copyright-link">Privacy</a>
              <a href="https://www.ebth.com/licenses" className="footer__copyright-link">Licenses</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Layout.propTypes = {
  hideActions: PropTypes.bool,
  pageName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
